import BookNow from "../../Components/BookNow/BookNowPageCom";
import Feature from "../../Components/Feature/feature";
import Faq from "../../Components/Faqs/faq";
import Testimonial from "../../Components/Testimonial/Testimonial";
import About from '../../Components/AboutUs/About'
import FloatingWhatsAppButton from "../../Components/FloatWhatsapp/FloatingWhatsAppButton";
// import useStore from "../../Store/store";
import Carousel from "../../CarouselComponent/CarouseComponent";
import WhyWe from "../../Components/WhyWe/WhyWe";
import HowToBook from "../../Components/HowToBook/HowToBook";





const Home = () => {
  // const {isStoreDarkMode}=useStore();
  // console.log("StoreDarkMood is :",isStoreDarkMode);
  return (
    <>

      <div className=" lg:mt-[4.6rem] mt-[4.6rem] min-w-full min-h-screen flex flex-col items-center lg:my-5">
       <Carousel/>
      <div className="lg:p-6 p-3 z-10">
      <BookNow/>
        <About/>
        <Feature/>
        <WhyWe/>
        <Faq/>
        <HowToBook/>
        <Testimonial/>
        <FloatingWhatsAppButton/>
      </div>
      </div>
    </>

  );
};

export default Home;
