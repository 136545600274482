import React from 'react';
import './feature.css';
import { IoEarth } from "react-icons/io5";
import { GiCityCar} from "react-icons/gi";
import { FaCity } from "react-icons/fa";
import { MdOutlinePrivacyTip } from "react-icons/md";

// import useStore from '../../Store/store';

const featuresList = [
  { icon: <IoEarth aria-label="Earth Icon" />, text: "Anywhere Delivery" },
  { icon: <GiCityCar aria-label="City Car Icon" />, text: "Fresh and Hygiene Maintained" },
  { icon: <MdOutlinePrivacyTip aria-label="Privacy Tip Icon" />, text: "Privacy and Freedom" },
  { icon: <FaCity aria-label="City Icon" />, text: "Kolkata" },
];

const FeatureBox = ({ icon, text }) => (
  <div className="feature-box border border-orange-600">
    <div className="icon ">{icon}</div>
    <p>{text}</p>
  </div>
);

const Feature = () => {

// const {isStoreDarkMode}=useStore();

  return (
    <div className="feature-container">
      <section className="features">
        <h1>Features & Benefits</h1>
        <div className="features-container">
          {featuresList.map((feature, index) => (
            <FeatureBox key={index} icon={feature.icon} text={feature.text} />
          ))}
        </div>
      </section>
    </div>
  );
};


export default Feature;
