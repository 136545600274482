import React from "react";

const CarModal = ({ isOpen, car, closeModal }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 min-h-screen bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white border border-gray-300 p-6 rounded-lg h-[580px] w-[90%] max-w-lg relative ">
        {/* Close Button */}
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-slate-800 hover:text-gray-700 text-2xl font-bold"
        >
          &times;
        </button>

        {/* Modal Content */}
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-semibold text-gray-800 mb-1">{car.name}</h2>
          <img
            src={car.image}
            alt={car.name}
            className="w-[320px] h-[200px] object-cover rounded-lg mb-2"
          />
          <p className=" text-black mb-2 text-center">{car.description}</p>
          <div className="text-sm  text-black mb-2">
            <strong>Vehicle Type:</strong> {car.type}
          </div>
          <div className="text-sm text-black mb-1">
            <strong>Seat Capacity:</strong> {car.seat}
          </div>
          <div className="text-sm mb-2 w-full">
            {/* <h3 className="text-lg font-bold text-gray-700 ">Pricing</h3> */}
            <div className=" rounded-lg shadow-lg ">
              <table className="w-full border-collapse border border-gray-200 text-sm ">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border border-gray-200 px-4 py-1 text-left text-black">Distance</th>
                    <th className="border border-gray-200 px-4 py-1 text-right  text-black">Rate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-200 px-4 py-1  text-black">0-50 kms</td>
                    <td className="border border-gray-200 px-4 py-1 text-right  text-black">{car.rates.rate1}</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200 px-4 py-1  text-black">50-100 kms</td>
                    <td className="border border-gray-200 px-4 py-1 text-right  text-black">{car.rates.rate2}</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200 px-4 py-1  text-black">100+ kms</td>
                    <td className="border border-gray-200 px-4 py-1 text-right  text-black">{car.rates.rate3}</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200 px-4 py-1  text-black">250+ kms</td>
                    <td className="border border-gray-200 px-4 py-1 text-right  text-black">{car.rates.rate4}/km</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Fixed "Rent Now" Button */}
        <div className="flex justify-center mt-4">
          <button
            onClick={() =>
              window.open(
                `https://wa.me/9547913428?text=Hey, I want to rent this car: ${car.name}`,
                "_blank"
              )
            }
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition-colors text-sm"
          >
            Rent Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarModal;
