import React from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FloatingWhatsAppButton from '../../Components/FloatWhatsapp/FloatingWhatsAppButton';
import Office from '../../Components/Office/Office';
import ContactForm from '../../Components/ContactFrom/ContactFrom';

const ContactUs = () => {
  const handleWhatsAppClick = () => {
    const defaultMessage =
      "I want to rent a car ";
    window.open(
      `https://wa.me/+919007837976?text=${encodeURIComponent(defaultMessage)}`,
      '_blank'
    );
  };

  return (
    <>
      <div className="p-6 max-w-7xl mt-[6.1rem] mx-auto rounded-lg shadow-lg">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold ">Contact Us</h1>
          <p className="text-lg  mt-4">
            We’d love to hear from you! Reach out for inquiries or follow us on social platforms.
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-8">
          {/* Contact Form */}
          
          <ContactForm/>

         {/* contact */}
         <div className="flex-1 text-center">
  <h2 className="text-3xl font-semibold  mb-6">Follow Us</h2>
  <div className="flex justify-center gap-6 mb-8">
    <a
      href="https://www.youtube.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="text-red-600 hover:scale-110 hover:text-red-700"
    >
      <YouTubeIcon fontSize="large" />
    </a>
    <a
      href="mailto:paradisecar24@gmail.com"
      target="_blank"
      rel="noopener noreferrer"
      className="text-orange-500 hover:text-orange-600 hover:scale-110"
    >
      <MarkunreadIcon fontSize="large" />
    </a>
    <a
      href="https://wa.me/+919007837976"
      target="_blank"
      rel="noopener noreferrer"
      className="text-green-500 hover:text-green-600 hover:scale-110"
    >
      <WhatsAppIcon fontSize="large" />
    </a>
    <a
      href="https://www.facebook.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-600 hover:text-blue-700 hover:scale-110"
    >
      <FacebookIcon fontSize="large" />
    </a>
    <a
      href="https://www.instagram.com/"
      target="_blank"
      rel="noopener noreferrer"
      className="text-pink-500 hover:text-pink-600 hover:scale-110"
    >
      <InstagramIcon fontSize="large" />
    </a>
  </div>

  {/* Additional Contact Details */}
  <div className="text-center space-y-6">
    <p className="text-3xl font-semibold  mb-6">Reach Us</p>
    {/* Office Section */}
    <div className="flex items-start space-x-4">
      <span className="p-2 border-2   border-yellow-400 rounded-full">
      <LocationOnIcon/>
      </span>
      <div className='text-left'>
        <h3 className="text-lg  font-bold ">Office</h3>
        <p className="">
        Paradise Car Rental,Narendranagar, Narayanpur, Monikhola, Kolkata, West Bengal 700136
        </p>
      </div>
    </div>

    {/* Phone Section */}
    <div className="flex text-left items-start space-x-4">
    
      <span className="p-2 border-2 border-yellow-400 rounded-full">
      <PhoneIcon/>
      </span>
      <div>
        <h3 className="text-lg font-bold ">Phone</h3>
        <a href='tel: 919007837976'>
        <p className="">+91-9007837976</p>
        </a>
      </div>
      
    </div>

    {/* Email Section */}
    <div className="flex text-left items-start space-x-4">
      <span className="p-2 border-2 border-yellow-400 rounded-full">
      <MailOutlineIcon/>
      </span>
      <div>
        <h3 className="text-lg font-bold ">Email</h3>
        <a href="mailto:paradisecar24@gmail.com">
        <p className=""> paradisecar24@gmail.com</p>
        </a>
      </div>
    </div>
  </div>
</div>

        </div>

        <Office />
        <FloatingWhatsAppButton onClick={handleWhatsAppClick} />
      </div>
    </>
  );
};

export default ContactUs;
