import React, { useState } from "react";
import { FaCar } from "react-icons/fa";
import { IoPeople } from "react-icons/io5";
import FloatingWhatsAppButton from "../../Components/FloatWhatsapp/FloatingWhatsAppButton";
import CarModal from "./CarModal"; 
import vehicleData from "../../Components/Vehicle/VehicleData";

const Cars = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);

  const handleWhatsAppMessage = (carName) => {
    const message = `Hey, I want to rent this car: ${carName}`;
    const whatsappUrl = `https://wa.me/9007837976?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  const openModal = (car) => {
    setSelectedCar(car);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCar(null);
  };

  return (
    <div className="flex flex-col items-center justify-center py-10 mb-5 mt-[5.1rem]">
      <FloatingWhatsAppButton />
      <h1 className="text-3xl font-bold  mb-8 text-center">
        Available Cars for Rent
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-[90%] max-w-6xl">
        {vehicleData.map((car) => (
          <div
            key={car.id}
            className="border border-gray-200 shadow-lg rounded-lg overflow-hidden flex flex-col items-center"
          >
            <img
              src={car.image}
              alt={car.name}
              className="w-full h-48 object-cover m-3"
            />
            <div className="p-4 flex flex-col items-center">
              <h2 className="text-xl font-semibold  mb-2 text-center">
                {car.name}
              </h2>
              <div className="flex items-center  text-sm mb-2">
                <FaCar className="text-yellow-500 mr-2" />
                <p>Vehicle Type: {car.type}</p>
              </div>
              <p className=" mb-4 text-center">{car.description}</p>
              <div className="flex items-center  text-sm mb-4">
                <IoPeople className="mr-2" />
                <p>
                  Seat Capacity: <b>{car.seat}</b>
                </p>
              </div>
              <div className="flex space-x-4 w-full justify-center">
                <button
                  onClick={() => openModal(car)}
                  className="bg-green-500 text-white font-bold py-2 px-6 rounded hover:bg-green-600 transition-colors"
                >
                  View Details
                </button>
                <button
                  onClick={() => handleWhatsAppMessage(car.name)}
                  className="bg-blue-500 text-white font-bold py-2 px-6 rounded hover:bg-blue-600 transition-colors"
                >
                  Rent Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <CarModal isOpen={isModalOpen} car={selectedCar} closeModal={closeModal} />
    </div>
  );
};

export default Cars;
