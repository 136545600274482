import React from "react";
import FloatingWhatsAppButton from "../../Components/FloatWhatsapp/FloatingWhatsAppButton";
import Expand from "../../Components/expand/expand";

const Faq = () => {
  const faqs = [
    {
      question: "What Is Paradise Car Rental Service?",
      answer:
        "Paradise car rental service provides the best journey to your destination.",
    },
    {
      question: "Is Paradise Available In My City?",
      answer:
        "Yes, Paradise is available in multiple cities across the country. Check our website for your location.",
    },
    {
      question: "What Type Of Vehicles/Cars Does Paradise Offer?",
      answer:
        "We offer a wide range of vehicles, including SUVs, sedans, and hatchbacks.",
    },
    {
      question: "How Much Does It Cost To Rent A Car At Paradise?",
      answer:
        "The cost varies based on the car type and duration. Please visit our pricing page for more details.",
    },
    {
      question: "What Are The Advantages Of Availing Cars On Rent At Paradise?",
      answer:
        "We offer competitive pricing, well-maintained vehicles, and excellent customer service.",
    },
  ];

  return (
    <div className=" mt-20 px-4 lg:px-8 py-8 ">
      <h2 className="text-3xl font-bold text-center  mb-10">Frequently Asked Questions</h2>
      <div className=" space-y-5">
        {faqs.map((faq, index) => (
          <div key={index} className="bg-transparent rounded-lg " >
            <Expand 
              question={
                <div
                  id={`faq-question-${index}`}
                  className="px-4 py-3 b  text-lg font-medium   cursor-pointer hover:text-green-600"
                >
                  Q: {faq.question}
                </div>
              }
            >
              <p className="px-4 py-3   leading-relaxed">
                A: {faq.answer}
              </p>
            </Expand>
          </div>
        ))}
      </div>
      <FloatingWhatsAppButton />
    </div>
  );
};

export default Faq;
