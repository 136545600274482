import React, { useState } from "react";
import './Pricing.css'
import FloatingWhatsAppButton from "../../Components/FloatWhatsapp/FloatingWhatsAppButton";
import CarModal from "../Cars/CarModal";
import vehicleData from "../../Components/Vehicle/VehicleData";

const Pricing = () => {
  const [selectedCar, setSelectedCar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (vehicle) => {
    setSelectedCar(vehicle);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCar(null);
  };

  return (
    <div className="pricing-container">
      <FloatingWhatsAppButton />
      <h1>Vehicle Pricing</h1>
      <table className="pricing-table">
        <thead>
          <tr>
            <th>Vehicle Model</th>
            <th>0-50 kms</th>
            <th>50-100 kms</th>
            <th>100+ kms</th>
            <th>250+ kms</th>
          </tr>
        </thead>
        <tbody>
          {vehicleData.map((vehicle) => (
            <tr key={vehicle.id} onClick={() => openModal(vehicle)} style={{ cursor: "pointer" }}>
              <td>{vehicle.name}</td>
              <td>{vehicle.rates.rate1}</td>
              <td>{vehicle.rates.rate2}</td>
              <td>{vehicle.rates.rate3}</td>
              <td>{vehicle.rates.rate4}/km</td>
            </tr>
          ))}
        </tbody>
      </table>
      <CarModal isOpen={isModalOpen} car={selectedCar} closeModal={closeModal} />
    </div>
  );
};

export default Pricing;
