import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import img1 from "../Assets/Slider/welcome.jpeg";
import img2 from "../Assets/Slider/tagline.jpeg";
import img3 from "../Assets/Slider/Anywhere.jpeg";
import img4 from "../Assets/Slider/monthly rental.jpeg";
import img5 from "../Assets/Slider/airport.jpeg";
import img6 from "../Assets/Slider/outstation.jpeg";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "./swiperStyles.css"; // Import the CSS file to hide the arrows

const Carousel = () => {
  const posters = [
    {
      id: 1,
      src: img1,
      alt: "Welcome to our website",
    },
    {
      id: 2,
      src: img2,
      alt: "Welcome to our website",
    },
    {
      id: 3,
      src: img3,
      alt: "Welcome to our website",
    },
    {
      id: 4,
      src: img4,
      alt: "Welcome to our website",
    },
    {
      id: 5,
      src: img5,
      alt: "Welcome to our website",
    },
    {
      id: 6,
      src: img6,
      alt: "Welcome to our website",
    },
  ];

  return (
    <div className="w-full sm:h-[200px] lg:h-[610px]">
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        className=" w-full sm:h-[200px] lg:h-[610px]  shadow-md"
      >
        {posters.map((poster, index) => (
          <SwiperSlide key={poster.id}>
            <img
  src={poster.src}
  alt="Slide 1"
  className="w-full sm:h-[200px] lg:h-[600px] object-fill"
/>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Carousel;
