import Xcent from "../../Assets/Xcent.png";
import Dzire from "../../Assets/Dzire.png";
import Innova from "../../Assets/Innova.png";
import Scorpio from "../../Assets/Scorpio.png";
import Ertiga from "../../Assets/Ertiga.png";
import Crysta from "../../Assets/Crysta.png";
import XUV500 from "../../Assets/XUV500.png";
import Bolero from "../../Assets/Bolero.png";

const vehicleData = [
    {
      id: 1,
      name: "Hyundai Xcent",
      image: Xcent, // Adjust the path to your assets folder
      type: "Sedan",
      description: "Stylish and powerful",
      seat: 4,
      rates: {
        rate1: "₹1200",
        rate2: "₹2000",
        rate3: "₹2500",
        rate4: "₹16",
      },
    },
    {
      id: 2,
      name: "Suzuki Dzire",
      image: Dzire,
      type: "Sedan",
      description: "Stylish and powerful",
      seat: 4,
      rates: {
        rate1: "₹1300",
        rate2: "₹2200",
        rate3: "₹2600",
        rate4: "₹17",
      },
    },
    {
      id: 3,
      name: "Toyota Innova",
      image: Innova,
      type: "SUV",
      description: "Spacious and comfortable",
      seat: 7,
      rates: {
        rate1: "₹1000",
        rate2: "₹2200",
        rate3: "₹3000",
        rate4: "₹25",
      },
    },
    {
      id: 4,
      name: "Mahindra Scorpio",
      image: Scorpio,
      type: "SUV",
      description: "Rugged and reliable",
      seat: 7,
      rates: {
        rate1: "₹1000",
        rate2: "₹2200",
        rate3: "₹3000",
        rate4: "₹25",
      },
    },
    {
      id: 5,
      name: "Suzuki Ertiga",
      image: Ertiga,
      type: "MPV",
      description: "Versatile and family-friendly",
      seat: 7,
      rates: {
        rate1: "₹1000",
        rate2: "₹1800",
        rate3: "₹2300",
        rate4: "₹20",
      },
    },
    {
      id: 6,
      name: "Toyota Innova Crysta",
      image: Crysta,
      type: "SUV",
      description: "Luxurious and powerful",
      seat: 7,
      rates: {
        rate1: "₹1400",
        rate2: "₹2200",
        rate3: "₹3200",
        rate4: "₹27",
      },
    },
    {
      id: 7,
      name: "Mahindra XUV 500",
      image: XUV500,
      type: "SUV",
      description: "Sporty and stylish",
      seat: 7,
      rates: {
        rate1: "₹1400",
        rate2: "₹2200",
        rate3: "₹3200",
        rate4: "₹27",
      },
    },
    {
      id: 8,
      name: "Mahindra Bolero",
      image: Bolero,
      type: "SUV",
      description: "Sturdy and dependable",
      seat: 10,
      rates: {
        rate1: "₹1500",
        rate2: "₹2500",
        rate3: "₹3500",
        rate4: "₹30",
      },
    },
  ];
  
  export default vehicleData;
  