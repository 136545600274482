import React from 'react';
import './Testimonial.css';
import jit from '../../Assets/Jit_Paul.png';
import shakti from '../../Assets/Shakti.png';
import rajdip from '../../Assets/rajdip.png';
import chandu from '../../Assets/chandu.png';

const Testimonial = () => {
  const testimonials = [
    {
      name: 'Shakti Pada Bhowmick',
      role: 'Customer',
      rating: 5,
      review: 'খুব সুন্দর একটা রাইড উপভোগ করলাম আমাদের এই নারায়নপুর থেকে বাগনান অব্দি এর আগেও আমি অন্য গাড়িতে গিয়েছি কিন্তু এত সুন্দর সার্ভিস এত সুন্দর ব্যবহার পাইনি কোথাও আপনাদেরও আমি অনুরোধ করছি এদের মাধ্যমে রাইড নিয়ে আপনারা দেখুন পরখ করে।',
      imageUrl: shakti,
    },
    {
      name: 'Jit Paul',
      role: 'Customer',
      rating: 5,
      review: 'Paradise Car rental provided me with an exceptional rental experience. From the moment I made my reservation online to returning the vehicle.',
      imageUrl: jit,
    },
    {
      name: 'Rajdeep Sarkar',
      role: 'Customer',
      rating: 5,
      review: 'I’ve rented cars from various companies, but Paradise Car rental truly stands out. Not only did they offer competitive prices.',
      imageUrl: rajdip,
    },
    {
      name: 'Soumyajit Ray',
      role: 'Customer',
      rating: 5,
      review: 'Pick up and drop was on time. Happy to see this kind of punctuality. I would also recommend others also to take its services for better experience.',
      imageUrl: chandu,
    },
  ];

  return (
    <div className="testimonial-section">
      <h2 className="section-title">What Our Clients Say</h2>
      <div className="testimonials-container flex flex-wrap gap-4 justify-center">
  {testimonials.map((testimonial, index) => (
    <div key={index} className="testimonial-card border border-emerald-600 p-4 rounded-lg shadow-md">
      {/* Image Container */}
      <div className="testimonial-image-container flex justify-center items-center border border-yellow-400 w-24 h-24 mx-auto rounded-full">
        <img src={testimonial.imageUrl} alt={testimonial.name} className="testimonial-image h-20 w-20 rounded-full" />
      </div>
      {/* Content */}
      <div className="testimonial-content mt-4 text-center">
        <h3 className="text-lg font-bold ">{testimonial.name}</h3>
        <p className="role text-sm ">{testimonial.role}</p>
        <div className="stars text-yellow-500 my-2">
          {'★'.repeat(testimonial.rating)}{'☆'.repeat(5 - testimonial.rating)}
        </div>
        <p className="review  text-sm">{testimonial.review}</p>
      </div>
    </div>
  ))}
</div>

    </div>
  );
};

export default Testimonial;
