import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import BookNowButton from "../Button/Button";
import { Contactservices } from "../../Services/ApiServices";

const ContactForm = () => {
  const [response, setResponse] = useState(null);
  const formRef = useRef();
  const [error, setError] = useState("");
console.log(error);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleFormData = async (data) => {
    try {
      setError("");
      setResponse(data);
      toast.success("Your contact data was sent successfully!");
      formRef.current.reset();
    } catch (error) {
      setError(error.message || "An unexpected error occurred.");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const postData = async () => {
      if (response) {
        try {
          const apiResponse = await Contactservices(response);
          console.log("Data sent successfully:", apiResponse);
        } catch (error) {
          console.error("Error sending data:", error);
        }
      }
    };

    if (response) postData();
  }, [response]);

  return (
    <div className="w-full lg:w-1/2 contact-form-container  lg:m-6 p-4 lg:p-6  shadow-md rounded-lg ">
      <h2 className="text-3xl text-center font-semibold mb-6 ">
        Leave Your Message
      </h2>
      <form
        onSubmit={handleSubmit(handleFormData)}
        ref={formRef}
        className="space-y-6"
      >
        <div>
          <label htmlFor="name" className="block font-medium mb-2">
            Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="Your Full Name"
            {...register("name", { required: "* Please enter your name" })}
            className="w-full p-3 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          {errors.name && (
            <p className="text-orange-800 text-sm mt-1">{errors.name.message}</p>
          )}
        </div>

        <div>
          <label htmlFor="email" className="block font-medium mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="Your Email Address"
            {...register("email", {
              required: "* Email is required",
              pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "* Please enter a valid email address",
              },
            })}
            className="w-full p-3 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          {errors.email && (
            <p className="text-orange-800 text-sm mt-1">
              {errors.email.message}
            </p>
          )}
        </div>

        <div>
          <label htmlFor="message" className="block font-medium mb-2">
            Message
          </label>
          <textarea
            id="message"
            placeholder="Write your message here..."
            {...register("message", { required: "* Please write a message" })}
            className="w-full p-3 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
            rows="5"
          ></textarea>
          {errors.message && (
            <p className="text-orange-800 text-sm mt-1">
              {errors.message.message}
            </p>
          )}
        </div>

        <div className="text-center">
          <BookNowButton label="Send Message" />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
