import React, { useState } from 'react';
import Airport from '../../Assets/Airport.png';
import outstation from '../../Assets/outstation.jpg';
import kilometer from '../../Assets/kilometer.png';
import Calendar from '../../Assets/calendar.png';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FloatingWhatsAppButton from "../../Components/FloatWhatsapp/FloatingWhatsAppButton";

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/BookNow");
    window.scrollTo(0, 0);
  };

  const services = [
    { 
      title: 'Airport Pickup & Drop', 
      description: 'Convenient and reliable airport transportation.', 
      details: 'We provide prompt, professional pickups and drop-offs to and from the airport, ensuring a comfortable ride.', 
      image: Airport 
    },
    { 
      title: 'Outstation Travel', 
      description: 'Enjoy one-way or round trips with flexible options.', 
      details: 'Ideal for outstation travel, we offer flexible one-way and round-trip options with comfortable vehicles to suit your needs.', 
      image: outstation 
    },
    { 
      title: 'Kilometer-wise Rental', 
      description: 'Pay only for the distance you travel.', 
      details: 'Flexible kilometer-wise rental options for cost-effective, customized trips.', 
      image: kilometer 
    },
    { 
      title: 'Monthly Rental', 
      description: 'Pay an amount and the car and driver is yours for a month.', 
      details: 'Flexible kilometer-wise rental options for cost-effective, customized trips.', 
      image: Calendar 
    },
  ];

  const openModal = (service) => {
    setCurrentService(service);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="mt-8 px-6 py-12 font-sans">
      <FloatingWhatsAppButton />
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-8">Our Services</h1>
        <div className="flex flex-wrap gap-6 justify-center">
          {services.map((service, index) => (
            <div 
              className="w-72 p-6 bg-transparent rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:-translate-y-2" 
              key={index}
            >
              <img src={service.image} alt={service.title} className="w-full h-40 object-cover rounded-md mb-4" />
              <h2 className="text-xl font-semibold mb-2">{service.title}</h2>
              <p className="text-sm  mb-4">{service.description}</p>
              <div className="flex justify-between items-center">
                <button 
                  className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
                  onClick={() => openModal(service)}
                >
                  Learn More
                </button>
                <Button onClick={handleNavigate} variant="outlined">
                  Book Now
                </Button>
              </div>
            </div>
          ))}
        </div>
        {showModal && currentService && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={closeModal}
          >
            <div 
              className="bg-gray-800 p-6 rounded-lg shadow-lg w-11/12 max-w-md relative"
              onClick={(e) => e.stopPropagation()}
            >
              <h2 className="text-2xl font-bold mb-4">{currentService.title}</h2>
              <p className=" mb-6">{currentService.details}</p>
              <button 
                className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;
