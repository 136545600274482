import './App.css';
import {Home} from './Pages/index'

function App() {
  return (
    <div className="  App flex flex-col justify-center py-3">
      <Home/>
   
    </div>
  );
}

export default App;
