import React, { useState } from 'react';
import { Button, TextField, MenuItem } from '@mui/material';

const MonthlyBookingForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    purpose: '',
    passengers: '',
    startDate: '',
    additionalDetails: '',
  });

  const purposes = [
    { label: 'School', value: 'school' },
    { label: 'Office', value: 'office' },
    { label: 'Other', value: 'other' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    alert('Booking Submitted Successfully!');
    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      phone: '',
      purpose: '',
      passengers: '',
      startDate: '',
      additionalDetails: '',
    });
  };

  return (
    <div className="bg-gray-100 py-8 px-4 rounded-lg shadow-lg max-w-3xl mx-auto mt-[5.1rem]">
      <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">For Monthly Booking </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <TextField
          fullWidth
          label="Full Name"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <TextField
          fullWidth
          label="Email Address"
          variant="outlined"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          required
        />
        <TextField
          select
          fullWidth
          label="Purpose of Booking"
          name="purpose"
          value={formData.purpose}
          onChange={handleInputChange}
          required
        >
          {purposes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          label="Number of Passengers"
          variant="outlined"
          type="number"
          name="passengers"
          value={formData.passengers}
          onChange={handleInputChange}
          required
        />
        <TextField
          fullWidth
          label="Start Date"
          variant="outlined"
          type="date"
          name="startDate"
          InputLabelProps={{ shrink: true }}
          value={formData.startDate}
          onChange={handleInputChange}
          required
        />
        <TextField
          fullWidth
          label="Additional Details (Optional)"
          variant="outlined"
          multiline
          rows={3}
          name="additionalDetails"
          value={formData.additionalDetails}
          onChange={handleInputChange}
        />
        <div className="flex justify-center">
          <Button type="submit" variant="contained" color="primary" className="px-6 py-2">
            Submit Booking
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MonthlyBookingForm;