import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider,createBrowserRouter } from 'react-router-dom';
import './index.css';
import { Toaster } from 'react-hot-toast';
// import Root from './Root/Root';
import { Services, ContactUs,  About, Error , Cars , Pricing , Faq, BookNow} from './Pages';
import App from './App';
import MonthlyBookingForm from './Pages/MonthlyBooking/MonthlyBooking';
import LoadingBlueSpinner from './Components/Loading/Loading';


const Root = lazy(() => waitPromise(1500).then(() => import('./Root/Root')));

// Simulate a promise for loading delay
const waitPromise = (time) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

// Set up your routes with React Router v6





const Route =createBrowserRouter([
  {
    path:"",
    element:(
      <Suspense fallback={<LoadingBlueSpinner/>}>
        <Root />
      </Suspense>
    ),
    children:[
      {
        path:"/",
        element:<App/>
      },
      {
        path:"/contactUs",
        element:<ContactUs/>

      },
      {
        path:"/about",
        element:<About/>
         
      },
       {
        path:"/services",
        element:<Services/>
       },

       {
        path:"/Pricing",
        element:<Pricing/>
       },
       {
        path:"/Cars",
        element:<Cars/>
       },
       {
        path:"/Faqs",
        element:<Faq/>
       },
       {
        path:"/BookNow",
        element:<BookNow/>
       },
       {
        path:"/monthlyBooking",
        element:<MonthlyBookingForm/>
       },
      
      {
        path:"*",
        element:<Error/>
      },
    ]
  }
])



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
         <Toaster />
    <RouterProvider router={Route}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a functio
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

